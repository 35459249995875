<template>
  <div class="mt-4">
    <div v-if="isLoading" class="d-flex justify-content-center m-5">
      <div class="spinner-border text-primary">
        <span class="sr-only">{{$t('state.loading')}}</span>
      </div>
    </div>
    <b-form v-if="!isLoading" style="padding: 0 2px;">
      <div class="w-100">
        <b-form-group
            :label="$t('cms.resource.key_name')"
            label-for="key-name"
            label-cols-lg="3"
            class="bg-light"
            style="padding: 10px 2px;"
        >
          <b-form-input
              id="key-name"
              type="text"
              v-model="keyName"
              :disabled="disableKeyNameInput"
              @update="setIsEditResource"
              trim></b-form-input>
        </b-form-group>

        <b-form-group
            :label="$t('cms.resource.slug')"
            label-for="slug"
            label-cols-lg="3"
        >
          <b-form-input
              id="slug"
              type="text"
              v-model="slug"
              @update="setIsEditResource"
              trim></b-form-input>
        </b-form-group>

        <b-form-group
            :label="$t('cms.resource.redirect_url')"
            label-for="redirect-url"
            label-cols-lg="3"
            class="bg-light"
            style="padding: 10px 2px;"
        >
          <b-form-input
              id="key-name"
              type="text"
              v-model="redirectUrl"
              @update="setIsEditResource"
              trim></b-form-input>
        </b-form-group>

        <b-form-group
            :label="$t('cms.resource.publishedAt')"
            label-for="publishedAt"
            label-cols-lg="3"
        >
          <b-form-datepicker id="publishedAt" :disabled="true" v-model="publishedAt"></b-form-datepicker>
        </b-form-group>

        <b-form-group
            :label="$t('cms.resource.unpublishedAt')"
            label-for="unpublishedAt"
            label-cols-lg="3"
            class="bg-light"
            style="padding: 10px 2px;"
        >
          <b-form-datepicker id="unpublishedAt" :disabled="true" v-model="unpublishedAt"></b-form-datepicker>
        </b-form-group>
      </div>
    </b-form>
    <div class="d-flex justify-content-between w-100 rounded mt-4 p-4 bg-light" style="margin: -4px; border: 2px solid #dc3545;">
      <span class="text-danger">
        {{ $t('cms.resource.remove_warning') }}
      </span>
      <b-button @click="deleteResource" size="md" variant="danger">
        {{ $t('cms.btn.delete_resource') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import alertify from "alertifyjs";

export default {
  computed: {
    ...mapState({
      isLoading: (state) => state.cms.isLoading,
      showValidationStatus: (state) => state.cms.showValidationStatus,
      resourceDetails: (state) => state.cms.resourceDetails,
      resourceId: (state) => state.cms.resourceDetails.id,
    }),
    // template: {
    //   get() {
    //     return this.$store.state.cms.resourceDetails.template;
    //   },
    //   set(value) {
    //     this.$store.commit('cms/SET_RESOURCE_FORM_TEMPLATE', value)
    //   }
    // },
    disableKeyNameInput() {
      return this.resourceId !== null;
    },
    keyName: {
      get() {
        return this.$store.state.cms.resourceDetails.keyName;
      },
      set(value) {
        this.$store.commit('cms/SET_RESOURCE_FORM_KEY_NAME', value)
      }
    },
    slug: {
      get() {
        return this.$store.state.cms.resourceDetails.slug;
      },
      set(value) {
        this.$store.commit('cms/SET_RESOURCE_FORM_SLUG', value)
      }
    },
    redirectUrl: {
      get() {
        return this.$store.state.cms.resourceDetails.redirectUrl;
      },
      set(value) {
        this.$store.commit('cms/SET_RESOURCE_FORM_REDIRECT_URL', value)
      }
    },
    publishedAt: {
      get() {
        return this.$store.state.cms.resourceDetails.publishedAt;
      },
      set(value) {
        this.$store.commit('cms/SET_RESOURCE_FORM_PUBLISHED_AT', value)
      }
    },
    unpublishedAt: {
      get() {
        return this.$store.state.cms.resourceDetails.unpublishedAt;
      },
      set(value) {
        this.$store.commit('cms/SET_RESOURCE_FORM_UNPUBLISHED_AT', value)
      }
    }
  },
  methods: {
    setIsEditResource() {
      this.$store.commit('cms/SET_IS_EDIT_RESOURCE', true);
    },
    deleteResource() {
      const message = `${this.$t('cms.modal.delete_resource_message')}: ${this.resourceDetails.title}`;

      this.$bvModal.msgBoxConfirm(message, {
        title: this.$t('cms.modal.delete_resource_title'),
        okTitle: this.$t('cms.modal.delete_resource.btn.delete'),
        cancelTitle: this.$t('cms.modal.delete_resource.btn.cancel'),
        okVariant: 'danger'
      })
      .then(value => {
        if(value) {
          this.$store.dispatch('cms/deleteResource', this.resourceDetails.id)
              .then(() => {
                this.$store.dispatch('cms/getResourceStructure').then(() => {
                  this.$router.push({ name: 'resources-list' });
                });
                alertify.success(this.$t('alert.success'));
              }).catch(() => {
            alertify.error(this.$t('alert.error'));
          });
        }
      });
    },
  }
}
</script>